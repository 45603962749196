
import { defineComponent } from "vue";

export default defineComponent({
  name: "VideosLibrary",
  setup() {

    const list = [
      {
        title: `Digestión de la Grasa Lactea`,
        file: `digestion-de-la-grasa-lactea.mp4`,
        imageUrl: `/img/videos-library/video-preview-a.png`,
      },
      {
        title: `¿Reducir la lactosa? ¡Piénsalo 2 veces!`,
        file: `reducir-la-lactosa-nuevo-logo-hcp.mp4`,
        imageUrl: `/img/videos-library/video-preview-b.png`,
      },
      {
        title: `Intolerancia a la lactosa`,
        file: `intolerancia-a-la-lactosa-nuevo-logo-hcp.mp4`,
        imageUrl: `/img/videos-library/video-preview-c.png`,
      },
    ];

    const thumbClick = (idx: number) => {
      const item = list[idx];
      const imageRepo = 'https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=vid&file=library/';
      const fileUrl = imageRepo + item['file'];
      window.open( fileUrl );
    }

    return {
      list,
      thumbClick,
    }
  }
});
