<template>
  <section class="bg-light h-full">
    <div class="container py-8">

      <div class="text-primary font-display font-bold text-5xl text-center mb-4">
        Vídeos
      </div>

      <div class="text-primary text-center mb-8">
        Seleccionar los artículos para saber más acerca de la ciencia detrás de los productos de Friso.
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

        <div 
          class="video-thumb h-56 rounded-xl p-4 bg-cover bg-center flex flex-col justify-end cursor-pointer" 
          v-for="(item, idx) in list"
          :key="`video-thumb-${idx}`"
          :style="{ backgroundImage: `url('${item.imageUrl}')`}" 
          @click="thumbClick(idx)"
        >
          <div class="text-white title" v-html="item.title"></div>
        </div>

      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VideosLibrary",
  setup() {

    const list = [
      {
        title: `Digestión de la Grasa Lactea`,
        file: `digestion-de-la-grasa-lactea.mp4`,
        imageUrl: `/img/videos-library/video-preview-a.png`,
      },
      {
        title: `¿Reducir la lactosa? ¡Piénsalo 2 veces!`,
        file: `reducir-la-lactosa-nuevo-logo-hcp.mp4`,
        imageUrl: `/img/videos-library/video-preview-b.png`,
      },
      {
        title: `Intolerancia a la lactosa`,
        file: `intolerancia-a-la-lactosa-nuevo-logo-hcp.mp4`,
        imageUrl: `/img/videos-library/video-preview-c.png`,
      },
    ];

    const thumbClick = (idx: number) => {
      const item = list[idx];
      const imageRepo = 'https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=vid&file=library/';
      const fileUrl = imageRepo + item['file'];
      window.open( fileUrl );
    }

    return {
      list,
      thumbClick,
    }
  }
});
</script>

<style scoped lang="scss">

.video-thumb {
  position: relative;

  &:before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0.7;
    z-index: 0;
    border-radius: 10px;

    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    background-image: url("/img/icon-play--white.svg");
    background-size: 40px auto;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .title {
    text-shadow: 0 0 3px rgba(0,0,0,.5);
    z-index: 10;
  }
}
</style>